import React from 'react';
import { graphql } from 'gatsby';
import ToolboxLayout from 'layouts/toolboxLayout';
import ContentSearchSection from 'components/Sections/ContentSearchSection';
import Cards from 'components/Core/Cards';
import { truncateText } from 'utils/text';
import { getImageSrc, getTitle, ContentTypeCategory } from 'components/Page/Toolbox/utils';
import queryString from 'query-string';
import { SectionLayout } from 'components/Sections/SectionLayout';
import styled from 'styled-components';

const Search = ({ data, location }) => {
  const { seo: seoContent } = data.contentfulContentHub;

  const allContent = Object.keys(data)
    .filter((x) => data[x]?.edges)
    .reduce((prev, cur) => {
      const d = data[cur].edges.map(({ node }) => ({ contentType: cur, __typename: cur, ...node }));
      return [...prev, ...d];
    }, []);

  const { filter } = queryString.parse(location.search);

  const topics = [
    { name: 'Commercial' },
    { name: 'Residential' },
    { name: 'Management' },
    { name: 'Business Tips' },
    { name: 'Marketing' },
    { name: 'Technician Tips' },
    { name: 'Operations' },
    { name: 'News' },
  ];

  const contentTypes = [
    { name: 'Blog' },
    { name: 'Webinar' },
    { name: 'Podcast' },
    { name: 'Templates' },
    { name: 'Tools' },
    { name: 'Guides' },
    { name: 'Success Stories', value: 'SuccessStory' },
    { name: 'Licensing' },
  ];

  const getCategory = (data) =>
    [
      ContentTypeCategory[data.__typename] || data.__typename,
      ...(data?.seo?.taxonomy?.topics || []),
      ...(data?.seo?.taxonomy?.businessFocus || []),
    ].join(' • ');

  const ItemCard = (data) => (
    <Cards
      type="Image"
      bodyType={'simple'}
      coverImage={getImageSrc(data)}
      category={getCategory(data)}
      title={truncateText(getTitle(data), 75)}
      ctaLink={data.seo?.pageUrl}
      key={data.id}
    />
  );

  const selectedTags = [...topics, ...contentTypes]
    .map((x) => x.name)
    .filter((x) => filter && x.toLowerCase() === filter.toLowerCase());

  return (
    <ToolboxLayout contentfulSeo={seoContent}>
      <SearchLayout>
        <ContentSearchSection
          allContent={allContent}
          topics={topics}
          contentTypes={contentTypes}
          pageSize={12}
          columns={3}
          ItemCard={ItemCard}
          fixedTags={selectedTags}
          selectedTag={filter}
          tagsCookie="search_tags"
          showTags={false}
          location={location}
        />
      </SearchLayout>
    </ToolboxLayout>
  );
};

export default Search;

export const SearchPage = graphql`
  query ToolboxSearchPageQuery {
    contentfulContentHub(internalName: { eq: "Toolbox" }) {
      ...ContentHub
    }
    Guides: allContentfulGatedContentPage {
      edges {
        node {
          __typename
          id
          title: contentOfferTitle
          publishDateRaw: updatedAt
          assetThumbnail {
            gatsbyImageData(placeholder: NONE, formats: [WEBP])
          }
          featuredImage {
            ...WebsiteCardImage
          }
          seo {
            ...SeoSearch
          }
        }
      }
    }
    Podcast: allContentfulPodcast(
      filter: { series: { in: ["Toolbox for the Trades", "Mastering ServiceTitan"] } }
    ) {
      edges {
        node {
          __typename
          id
          title: podcastTitle
          publishDateRaw: podcastDate
          cardPhoto {
            ...WebsiteCardImage
          }
          seo {
            ...SeoSearch
          }
        }
      }
    }
    Webinar: allContentfulWebinar {
      edges {
        node {
          __typename
          id
          title: webinarTitle
          publishDateRaw: webinarDate
          featuredImage {
            ...WebsiteCardImage
          }
          seo {
            ...SeoSearch
          }
        }
      }
    }
    Tools: allContentfulTool(filter: { displayTool: { eq: true } }) {
      edges {
        node {
          __typename
          id
          title: internalName
          publishDateRaw: updatedAt
          cardImage {
            ...WebsiteCardImage
          }
          seo {
            ...SeoSearch
          }
        }
      }
    }
    Blog: allContentfulBlog(
      filter: { tags: { nin: "Covid19" }, seo: { taxonomy: { topics: { ne: "Success Story" } } } }
    ) {
      edges {
        node {
          __typename
          id
          title
          publishDateRaw: publishDate
          featuredImage {
            gatsbyImageData(placeholder: NONE, formats: [WEBP])
          }
          seo {
            ...SeoSearch
          }
        }
      }
    }
    SuccessStory: allContentfulBlog(
      filter: { seo: { taxonomy: { topics: { eq: "Success Story" } } } }
    ) {
      edges {
        node {
          title
          id
          publishDateRaw: publishDate
          featuredImage {
            gatsbyImageData(placeholder: NONE, formats: [WEBP])
          }
          seo {
            ...SeoSearch
          }
        }
      }
    }
    Templates: allContentfulDownloadableContent(
      filter: { downloadableContentType: { eq: "Templates" } }
    ) {
      edges {
        node {
          __typename: downloadableContentType
          id
          title
          publishDateRaw: updatedAt
          featuredImage {
            ...WebsiteCardImage
          }
          seo {
            ...SeoSearch
          }
        }
      }
    }
    Licensing: allContentfulDownloadableContent(
      filter: { downloadableContentType: { eq: "Licensing" } }
    ) {
      edges {
        node {
          __typename: downloadableContentType
          id
          title
          publishDateRaw: updatedAt
          featuredImage {
            ...WebsiteCardImage
          }
          seo {
            ...SeoSearch
          }
        }
      }
    }
  }
`;

const SearchLayout = styled(SectionLayout)`
  @media (min-width: 1200px) {
    .section-layout--inner-container {
      padding-top: 0;
    }
  }
`;
